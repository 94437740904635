import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/backdrop/backdrop.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumb.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumbs.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumb-current-page.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/link/link.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/tooltip/tooltip-help.js';

import '../../../../shared/components/general/app-link/app-link.js';
import '../../../../shared/components/activities/activity-financial-details/activity-financial-details.js';
import '../../../../shared/components/activities/activity-small/activity-small.js';
import '../../../../shared/components/activities/activity-submit-form/activity-submit-form.js';

import { bodyCompactStyles, bodySmallStyles, heading1Styles, heading2Styles, heading3Styles, heading4Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles.js';
import { navigator as nav } from 'lit-element-router';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { Application } from '../../../../../shared/models/application/index.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { novaCardStyles } from '../../../../shared/components/general/nova-card/nova-card-styles.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

export default class ApplyActivity extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      params: { type: Object },
      _activity: { type: Object, attribute: false },
      _application: { type: Object, attribute: false },
      _country: { type: String, attribute: false },
      _courses: { type: Array, attribute: false },
      _employer: { type: Object, attribute: false },
      _errors: { type: Array, attribute: false },
      _existingApp: { type: Object, attribute: false },
      _postalCode: { type: String, attribute: false },
      _postalCodeInvalid: { type: Boolean, attribute: false },
      _provider: { type: Object, attribute: false },
      _selectedActivity: { type: Object, attribute: false },
      _templateType: { type: String, attribute: false },
      _validationInProgress: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      heading4Styles,
      inputLabelStyles,
      inputStyles,
      labelStyles,
      novaCardStyles,
      offscreenStyles,
      selectStyles,
      radioStyles,
      css`
        :host {
          display: block;
        }

        #intro-alert {
          min-width: 100%;
        }

        .apply-container {
          column-gap: 100px;
          display: grid;
          grid-template-columns: 1.75fr 1fr;
          margin: 0 auto;
          width: 1170px;
        }

        .alert-subtext {
          margin: 0;
        }

        .left-content-primary {
          grid-column: 1;
          grid-row-start: 1;
        }

        .left-content-secondary {
          grid-column: 1;
          grid-row: 2;
        }

        .right-content {
          grid-column: 2;
          grid-row: 1 / span 2;
        }

        .intro {
          margin-bottom: 3rem;
          margin-top: 0.6rem;
        }

        #intro-text {
          margin-bottom: 0.3rem;
        }

        #error-list {
          margin-top: 1.5rem;
        }

        .courses {
          margin-bottom: 1rem;
        }

        section:not(.apply-container) > :first-of-type {
          margin-bottom: 0;
        }

        section:not(.apply-container) activity-small:first-of-type {
          margin-top: 24px;
        }

        .separator-container {
          margin: 42px 0 36px 0;
          position: relative;
          width: 100%;
        }

        .separator-container::before {
          border-bottom: 1px solid var(--d2l-color-mica);
          content: '';
          left: 0;
          position: absolute;
          top: 50%;
          width: 100%;
          z-index: 1;
        }

        .separator-text {
          background-color: var(--d2l-wave-white);
          display: inline-block;
          left: 12.5%;
          padding: 0 12px;
          position: relative;
          width: auto;
          z-index: 2;
        }

        .activity-details {
          position: sticky;
          top: 0.6rem;
        }

        .select-activity {
          margin-bottom: 60px;
          margin-top: 48px;
        }

        #title {
          margin-bottom: 0.6rem;
        }

        .tos {
          height: 580px;
          width: 100%;
        }

        .d2l-heading-2 {
          margin-bottom: 18px;
          margin-top: 42px;
        }

        @media (max-width: 1280px) {
          .apply-container {
            grid-template-columns: 1fr;
            max-width: 767px;
            width: unset;
          }

          .right-content {
            grid-column: 1;
            grid-row: 2;
            margin-bottom: 1.2rem;
          }

          .left-content-secondary {
            grid-row: 3;
          }

          .d2l-heading-1 {
            margin: 0.6rem 0;
          }

          .intro-text {
            margin-bottom: 1.2rem;
            margin-top: 0;
          }

          .d2l-heading-2 {
            margin-bottom: 0.4rem;
            margin-top: 0.8rem;
          }

          .select-activity {
            margin-bottom: 0.6rem;
            margin-top: 0.6rem;
          }

          section:not(.apply-container) > :first-of-type {
            margin-top: 0;
          }

          section:not(.apply-container) activity-small:first-of-type {
            margin-top: 0.6rem;
          }

          .separator-container {
            margin: 0.8rem 0 0.6rem;
          }

          .activity-details {
            position: unset;
            top: unset;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._activity = new Activity();
    this._application = new Application();
    this._courses = [];
    this._employer = new Tenant();
    this._errors = [];
    this._postalCode = '';
    this._postalCodeInvalid = false;
    this._provider = new Tenant();
    this._validationInProgress = false;
    this._selectedActivity = new Activity();
    this._tosVersion = '1.0';
  }

  get showTOS() {
    if (!this._showTOS) this._showTOS = this.shadowRoot.getElementById('show-tos-dialog');
    return this._showTOS;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    this._application = new Application({ user: this.session.user });

    this.skeleton = true;
    this._fetchData();
    this._initializeCourses();
    this._determineRequestedApprover();
    this._determineTemplateType();
    this.skeleton = false;
  }

  updated(changedProperties) {
    if (changedProperties.has('_activity')) {
      if (this._activity?.id) {
        this.client.logEvent({
          eventType: 'applyActivityViewed',
          activityId: this._activity.id,
        });
      }
    }
  }

  render() {
    if (!this._activity?.id || !this._provider?.id || !this._employer?.id) {
      return html`${this.localize('apply-activity.loading')}`;
    }
    return html`
      <section class="apply-container" id="apply-container">
        <div class="left-content-primary">
          <d2l-breadcrumbs compact>
              <d2l-breadcrumb text="${this.localize('apply-activity.breadcrumb')}" href="${`/activities/${this.params.id}`}" ></d2l-breadcrumb>
          </d2l-breadcrumbs>
          <h1 class="d2l-heading-1 d2l-skeletize" id="title">${this.localize('apply-activity.title')}</h1>
          ${this._introduction}
          <div id="error">
          ${this._errors.length > 0 ? html`
            <d2l-alert id="error-list" type="critical">
              <ul>
                ${this._errors.map(error => html`
                <li>${error}</li>
                `)}
              </ul>
            </d2l-alert>
          ` : nothing}
          </div>
          <h2 class="d2l-body-compact d2l-skeletize d2l-heading-2">${this.localize('apply-activity.select.title')}</h2>
          ${this._selectTemplate(this._templateType)}
        </div>
        <activity-submit-form
          class="left-content-secondary"
          .activity=${this._activity}
          .courses=${this._courses}
          .backlink=${this.params.id}
          .selectedActivity=${this._selectedActivity}
          .operatingCurrency=${this._provider.operatingCurrency}
          @request-review-submit-updates=${this._handleRequestReviewUpdates}>
        </activity-submit-form>
        <div class="right-content">
          <div class="activity-details d2l-skeletize">
            ${this._employer.hasTag('paymentHandledByProvider') ? nothing : html`<activity-financial-details
              applying
              draft
              .activity=${this._selectedActivity}
              .application=${this._application}
              .userGuid=${this.session.userGuid}
              .provider=${this._provider}
              .tenant=${this._employer}>
            </activity-financial-details>`}
          </div>
        </div>
      </section>
      <d2l-backdrop for-target="apply-container" ?shown=${this._validationInProgress}></d2l-backdrop>
      <d2l-dialog id="show-tos-dialog" title-text="${this.localize('apply-activity.tos')}">
          <iframe src="/assets/tos/terms-of-use-${this.session.settings.language === 'fr' ? 'fr' : 'en'}.html" frameborder="0" scrolling="auto" aria-live="assertive" class="tos"></iframe>
          <d2l-button id="cancelButton" slot="footer" @click=${this._closeDialog} data-dialog-action>${this.localize('apply-activity.buttonClose')}</d2l-button>
      </d2l-dialog>
    `;
  }

  get _activityDisplayAttributes() {
    return ['formattedCost', 'formattedStartDate'];
  }

  get _existingAppAlert() {
    return this._existingApp ? html`
      <d2l-alert id="intro-alert" type="default">
        ${this.localize('apply-activity.existingApp.header')}
        <p class="d2l-body-compact alert-subtext">${this.localize('apply-activity.existingApp.subtext')} <app-link d2l-link href="/requests/${this._existingApp.uuid}">${this.localize('apply-activity.existingApp.subtext.link')}</app-link></p>
      </d2l-alert>
    ` : nothing;
  }

  get _introduction() {
    return html`
      <div class="intro">
        <div id="intro-text" class="d2l-skeletize d2l-body">${this._application.getTranslatedApprovers(this._employer, 'apply-activity.introduction')}</div>
        ${this._existingAppAlert}
      </div>
    `;
  }

  _closeDialog() {
    this.showTOS.opened = false;
  }

  _coursesTemplate() {
    const courseTemplate = course => {
      if (!course.hasTag('active')) return nothing;
      const isScheduled = course.isScheduled();
      const role = isScheduled ? undefined : 'radio';
      const tabindex = isScheduled ? -1 : 0;
      const tooltip = isScheduled ? nothing : html`<d2l-tooltip>${this.localize('activity-small.notScheduledDescription')}</d2l-tooltip>`;
      return html`
        <activity-small
          ?skeleton=${this.skeleton}
          .displayAttributes=${this._activityDisplayAttributes}
          .activity=${course}
          ?selected=${this._selectedActivity && this._selectedActivity.id === course.id}
          no-nav
          .disabled="${!isScheduled}"
          @click=${this._onRadioClick(course)}>
          <div slot="actions" role="${ifDefined(role)}" tabindex="${tabindex}">
            <input
              type="radio"
              class="d2l-input-radio d2l-skeletize"
              tabindex="-1"
              .disabled="${!isScheduled}"
              .checked=${this._selectedActivity && this._selectedActivity.id === course.id}
              aria-label="${course.title}"/>
            ${tooltip}
          </div>
        </activity-small>
      `;
    };
    return html`
      <section class="courses">
        <h4 class="d2l-heading-4 d2l-skeletize">${this.localize('apply-activity.course.request.title')}</h4>
        ${this._courses.sort((a, b) => a.meta?.order - b.meta?.order).map(courseTemplate)}
      </section>
    `;
  }

  _determineRequestedApprover() {
    const ra = this._employer.determineRequestedApprover(this.session.user);
    if (!ra.id) {
      if (this.session.user.tenantType === 'employer') {
        this.session.toast({ message: `${this.localize('apply-activity.noManager.toast')}` });
      } else { // It's a provider or admin
        this.session.toast({ message: `${this.localize('apply-activity.error.cantApply')}` });
      }
    }
  }

  /**
   * Determines which template type needs to be rendered(just course, just program, or a combo)
   */
  _determineTemplateType() {
    let res = '';
    if (this._activity.type === 'program') {
      if (this._activity.hasTag('allowRequest')) {
        res += 'program';
      }
      if (this._courses.length > 0) {
        res += 'courses';
      }
    } else {
      res += 'course';
    }
    this._templateType = res;
  }

  async _fetchData() {
    const [activity, employer] = await Promise.all([
      this.client.fetchActivity(this.params.id),
      this.client.fetchTenant(this.session.tenantId),
    ]);
    this._activity = activity;
    this._employer = employer;
    this._provider = await this.client.fetchTenant(this._activity.provider);
    try {
      const existingApps = await this.client.getApplicationsForActivity(this._activity.id);
      this._existingApp = new Application(existingApps[0]);
    } catch (e) {
      if (e.status !== 404) {
        throw e;
      }
    }
  }

  _handleRequestReviewUpdates(e) {
    if (e && e.target) {
      this._errors = e.detail.errors;
      this._validationInProgress = e.detail.validationInProgress;
    }
    this.requestUpdate();
    if (this._errors.length > 0) {
      this._showErrorAlert();
    }
  }

  async _initializeCourses() {
    this._courses = [];
    if (this._activity.type === 'program') {
      this._courses = this._activity.children.filter(course => course.hasTag('allowRequest'));
    }
  }

  _onRadioClick(activity) {
    return () => {
      if (activity.isScheduled()) this._selectedActivity = activity;
    };
  }

  _selectTemplate(config) {
    switch (config) {
      case 'program':
        this._selectedActivity = this._activity;
        return html`
        <div class="select-activity">
          <activity-small
            disabled
            ?skeleton=${this.skeleton}
            .displayAttributes=${this._activityDisplayAttributes}
            .activity=${this._activity}>
          </activity-small>
        </div>`;
      case 'course':
        this._selectedActivity = this._activity;
        return html`
        <div class="select-activity">
          <activity-small
            disabled
            ?skeleton=${this.skeleton}
            .displayAttributes=${this._activityDisplayAttributes}
            .activity=${this._activity}>
          </activity-small>
        </div>`;
      case 'courses':
        return html`
          <div class="select-activity">
            ${this._coursesTemplate()}
          </div>`;
      case 'programcourses':
        return html`
        <div class="select-activity">
          <section class="program">
            <h4 class="d2l-heading-4 d2l-skeletize">${this.localize('apply-activity.program.request.title')}</h4>
            <activity-small
              ?skeleton=${this.skeleton}
              .displayAttributes=${this._activityDisplayAttributes}
              .activity=${this._activity}
              ?selected=${this._selectedActivity && this._selectedActivity.id === this._activity.id}
              no-nav
              @click=${this._onRadioClick(this._activity)}>
              <div slot="actions">
                <input
                  type="radio"
                  class="d2l-input-radio d2l-skeletize"
                  tabindex="-1"
                  .checked=${this._selectedActivity && this._selectedActivity.id === this._activity.id}
                  aria-label="${this._activity.title}"/>
              </div>
            </activity-small>
          </section>
          <div class="separator-container d2l-skeletize">
            <p class=" separator-text d2l-body-small">${this.localize('apply-activity.OR')}</p>
          </div>
          ${this._coursesTemplate()}
        </div>`;
      default:
        return '';
    }
  }

  _showErrorAlert() {
    const errorAlert = this.shadowRoot.getElementById('error');
    errorAlert.scrollIntoView({ block: 'center', inline: 'center' });
    errorAlert.focus();
  }
}

window.customElements.define('apply-activity', ApplyActivity);
